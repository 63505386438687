import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Modal from '../modal'
import history from '../../history'
import { connect } from 'react-redux'
import { fetchItem, deleteItem } from '../../actions'

class ItemDelete extends React.Component {
  componentDidMount() {
    this.props.fetchItem(this.props.match.params.id)
  }

  renderActions() {
    const { id } = this.props.match.params
    return (
      <Fragment>
        <button
          onClick={() => this.props.deleteItem(id)}
          className="ui negative button"
        >
          Delete
        </button>
        <Link to="/shopping-list" className="ui button">
          Cancel
        </Link>
      </Fragment>
    )
  }

  renderContent() {
    if (!this.props.item) {
      return 'Are you sure you want to delete this shopping list item?'
    }
    return `Are you sure you want to delete the shopping list item: ${this.props.item.item}`
  }

  render() {
    return (
      <Modal
        title="Delete Shopping List Item"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={() => history.push('/shopping-list')}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return { item: state.items[ownProps.match.params.id] }
}

export default connect(mapStateToProps, { fetchItem, deleteItem })(ItemDelete)
