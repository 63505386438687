import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchItems } from '../../actions'
import { isMobile } from 'react-device-detect'

class ItemList extends React.Component {
  componentDidMount() {
    this.props.fetchItems()
  }

  renderAdmin(item) {
    if (item.userId === this.props.currentUserId) {
      return (
        <div
          className={`right floated content ${
            isMobile ? 'ui icon buttons' : ''
          }`}
        >
          <Link
            to={`/shopping-list/item/edit/${item.id}`}
            className="ui button primary"
          >
            {isMobile ? <i className="icon pencil alternate" /> : 'Edit'}
          </Link>
          <Link
            to={`/shopping-list/item/complete/${item.id}`}
            className="ui button positive"
          >
            {isMobile ? <i className="icon shopping basket" /> : 'Complete'}
          </Link>
          <Link
            to={`/shopping-list/item/delete/${item.id}`}
            className="ui button negative"
          >
            {isMobile ? (
              <i className="icon trash alternate outline" />
            ) : (
              'Delete'
            )}
          </Link>
        </div>
      )
    }
  }

  renderList() {
    return this.props.items.map((item) => {
      if (this.props.currentUserId !== item.userId) return null
      return (
        <div className="item" key={item.id}>
          <div className="content">
            <div className="header">{item.item}</div>
            <div className="meta">
              <div className="floated right"></div>
              {this.renderAdmin(item)}
            </div>

            <div className="description">
              {item.amount} {item.unit ? `${item.unit}(s)` : ''}{' '}
              {item.modification}
            </div>
          </div>
        </div>
      )
    })
  }

  renderCreate() {
    if (this.props.isSignedIn) {
      return (
        <div
          className={`${isMobile ? 'ui icon buttons' : ''}`}
          style={{ textAlign: 'right' }}
        >
          <Link to="/shopping-list/item/new" className="ui button primary">
            {isMobile ? (
              <i className="icon plus" />
            ) : (
              'Create Shopping List Item'
            )}
          </Link>
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        <h2 className="ui floated left header">Shopping List</h2>
        <div className="floated right">{this.renderCreate()}</div>
        <div className="ui divided items">{this.renderList()}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    items: Object.values(state.items),
    currentUserId: state.auth.id,
    isSignedIn: state.auth.isSignedIn,
  }
}

export default connect(mapStateToProps, { fetchItems })(ItemList)
