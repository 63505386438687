import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { createCartItem } from '../../actions'
import CartItemForm from './CartItemForm'

class CartItemCreate extends React.Component {
  onSubmit = (formValues) => {
    this.props.createCartItem(formValues, formValues)
  }

  render() {
    return (
      <div>
        <h3>Create A Shopping Cart Item</h3>
        <CartItemForm
          initialValues={_.pick(
            this.props.item,
            'item',
            'amount',
            'cost',
            'unit',
            'modification',
            'store'
          )}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

export default connect(null, { createCartItem })(CartItemCreate)
