import _ from 'lodash'
import {
  CREATE_CART_ITEM,
  FETCH_CART_ITEMS,
  FETCH_CART_ITEM,
  DELETE_CART_ITEM,
  EDIT_CART_ITEM,
} from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CART_ITEM:
      return { ...state, [action.payload.id]: action.payload }
    case CREATE_CART_ITEM:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_CART_ITEM:
      return { ...state, [action.payload.id]: action.payload }
    case DELETE_CART_ITEM:
      return _.omit(state, action.payload)
    case FETCH_CART_ITEMS:
      return { ...state, ..._.mapKeys(action.payload, 'id') }
    default:
      return state
  }
}
