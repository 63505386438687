import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { fetchSetting, editSetting } from '../../actions'
import SettingForm from './SettingForm'

class Settings extends React.Component {
  componentDidMount() {
    this.props.fetchSetting()
  }

  onSubmit = (formValues) => {
    this.props.editSetting(this.props.settings.id, formValues)
  }

  render() {
    if (!this.props.settings) {
      return <div></div>
    }
    return (
      <div>
        <h3>Edit Settings</h3>
        <SettingForm
          initialValues={_.pick(
            this.props.settings,
            'currency',
            'units',
            'stores'
          )}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { settings: state.settings[0] }
}

export default connect(mapStateToProps, { fetchSetting, editSetting })(Settings)
