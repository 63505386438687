import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchSetting } from '../../actions'
import { Field, FieldArray, reduxForm } from 'redux-form'
import renderInput from '../form/formInput'
import renderSelect from '../form/formSelect'

class RecipeForm extends React.Component {
  componentDidMount() {
    this.props.fetchSetting()
  }

  getUnits = (units) => {
    return units.map((x, index) => (
      <option key={index} value={x['unit']}>
        {x['unit']}
      </option>
    ))
  }

  renderIngredients = ({ fields, meta }) => (
    <React.Fragment>
      <h4>Ingredients</h4>
      <ul>
        {fields.map((ingredient, index) => (
          <li key={index}>
            <button
              className="ui icon red button"
              type="button"
              title="Remove Ingredient"
              onClick={() => fields.remove(index)}
            >
              <i className="trash icon"></i>
            </button>
            <Field
              name={`${ingredient}.amount`}
              type="number"
              component={renderInput}
              label={`Amount`}
            />

            <Field
              name={`${ingredient}.unit`}
              component={renderSelect}
              label={`Unit`}
            >
              <option />
              {this.props.settings
                ? this.getUnits(this.props.settings.units)
                : null}
            </Field>
            <Field
              name={`${ingredient}.ingredient`}
              type="text"
              component={renderInput}
              label={`Ingredient`}
            />
            <Field
              name={`${ingredient}.modification`}
              type="text"
              component={renderInput}
              label={`Modification`}
            />
          </li>
        ))}
        <li>
          <button
            className="ui labeled icon button"
            type="button"
            onClick={() => fields.push()}
          >
            <i className="plus icon"></i>Add Ingredient
          </button>
        </li>
      </ul>
      {(meta.touched || meta.submitFailed) && meta.error && (
        <div className={`${meta.error ? 'error' : ''}`}>
          <div className="ui error message">
            <div className="header">{meta.error}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  )

  renderDirections = ({ fields, meta }) => (
    <React.Fragment>
      <h4>Directions</h4>
      <ul>
        {fields.map((direction, index) => (
          <li key={index}>
            <button
              className="ui icon red button"
              type="button"
              title="Remove Direction Step"
              onClick={() => fields.remove(index)}
            >
              <i className="trash icon"></i>
            </button>
            {/* TODO: Add Direction Image Here possibly reference https://stackoverflow.com/questions/39698285/how-to-upload-file-with-redux-form */}
            <Field
              name={`${direction}.step`}
              type="text"
              component={renderInput}
              label={`Step`}
            />
          </li>
        ))}
        <li>
          <button
            className="ui labeled icon button"
            type="button"
            onClick={() => fields.push()}
          >
            <i className="plus icon"></i>Add Direction
          </button>
        </li>
      </ul>
      {(meta.touched || meta.submitFailed) && meta.error && (
        <div className={`${meta.error ? 'error' : ''}`}>
          <div className="ui error message">
            <div className="header">{meta.error}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  )

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues)
  }

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field name="title" component={renderInput} label="Title" />
        <Field name="description" component={renderInput} label="Description" />
        <Field
          name="prep"
          type="number"
          component={renderInput}
          label="Prep Time"
          unit="minutes"
        />
        <Field
          name="cook"
          type="number"
          component={renderInput}
          label="Cook Time"
          unit="minutes"
        />
        <Field
          name="makes"
          type="number"
          component={renderInput}
          label="Yield"
        />
        <Field
          name="servings"
          type="number"
          component={renderInput}
          label="Servings"
        />
        <Field
          name={`servingunit`}
          component={renderSelect}
          label={`Serving Unit`}
        >
          <option />
          {this.props.settings
            ? this.getUnits(this.props.settings.units)
            : null}
        </Field>
        <FieldArray name="ingredients" component={this.renderIngredients} />
        <FieldArray name="directions" component={this.renderDirections} />
        <button className="ui button primary">Submit</button>
        <Link to="/recipes" className="ui button">
          Cancel
        </Link>
      </form>
    )
  }
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.title) {
    errors.title = 'You must enter a title'
  }
  if (!formValues.description) {
    errors.description = 'You must enter a description'
  }
  if (!formValues.prep) {
    errors.prep = 'You must enter a prep time'
  }
  if (!formValues.cook) {
    errors.cook = 'You must enter a cook time'
  }
  if (!formValues.makes) {
    errors.makes = 'You must enter a yield'
  }
  if (!formValues.servings) {
    errors.servings = 'You must enter a serving'
  }
  if (!formValues.servingunit) {
    errors.servingunit = 'You must enter a serving unit'
  }
  if (!formValues.ingredients || !formValues.ingredients.length) {
    errors.ingredients = { _error: 'You must have at least one ingredient' }
  } else {
    const ingredientArrayErrors = []
    formValues.ingredients.forEach((ingredient, index) => {
      const ingredientErrors = {}
      if (!ingredient || !ingredient.amount) {
        ingredientErrors.amount = 'you must enter an amount'
        ingredientArrayErrors[index] = ingredientErrors
      }
      if (!ingredient || !ingredient.unit || ingredient.unit === '') {
        ingredientErrors.unit = 'you must select an unit'
        ingredientArrayErrors[index] = ingredientErrors
      }
      if (!ingredient || !ingredient.ingredient) {
        ingredientErrors.ingredient = 'you must enter an ingredient'
        ingredientArrayErrors[index] = ingredientErrors
      }
    })
    if (ingredientArrayErrors.length) {
      errors.ingredients = ingredientArrayErrors
    }
  }
  if (!formValues.directions || !formValues.directions.length) {
    errors.directions = { _error: 'You must have at least one direction' }
  } else {
    const directionArrayErrors = []
    formValues.directions.forEach((direction, index) => {
      const directionErrors = {}
      if (!direction || !direction.step) {
        directionErrors.step = 'you must enter a direction step'
        directionArrayErrors[index] = directionErrors
      }
    })
    if (directionArrayErrors.length) {
      errors.directions = directionArrayErrors
    }
  }

  return errors
}

const mapStateToProps = (state) => {
  return { settings: state.settings[0] }
}

export default reduxForm({
  form: 'recipeForm',
  validate,
})(connect(mapStateToProps, { fetchSetting })(RecipeForm))
