import API from '../apis/api'
import history from '../history'
import {
  SIGN_IN,
  SIGN_OUT,
  CREATE_RECIPE,
  FETCH_RECIPES,
  FETCH_RECIPE,
  DELETE_RECIPE,
  EDIT_RECIPE,
  CREATE_ITEM,
  FETCH_ITEM,
  FETCH_ITEMS,
  DELETE_ITEM,
  EDIT_ITEM,
  CREATE_CART_ITEM,
  FETCH_CART_ITEM,
  FETCH_CART_ITEMS,
  DELETE_CART_ITEM,
  EDIT_CART_ITEM,
  CREATE_SETTING,
  FETCH_SETTING,
  EDIT_SETTING,
} from './types'

// ----------------- Auth ------------------- //
export const signIn = (googleId) => async (dispatch, getState) => {
  const userId = getState().auth.id
  let thePayload = { googleId }
  if (!userId) {
    const response = await API.post('/user', { googleId: googleId })
    thePayload = response.data
  }
  dispatch({ type: SIGN_IN, payload: thePayload })
}

export const signOut = () => {
  return {
    type: SIGN_OUT,
  }
}

// ---------------- Recipes ----------------- //
export const createRecipe = (formValues) => async (dispatch, getState) => {
  const userId = getState().auth.id
  const response = await API.post('/recipe', { ...formValues, userId })

  dispatch({ type: CREATE_RECIPE, payload: response.data })
  history.push('/recipes')
}

export const fetchRecipes = () => async (dispatch, getState) => {
  const userId = getState().auth.id
  const response = await API.get(`/recipe/user/${userId}`)

  dispatch({ type: FETCH_RECIPES, payload: response.data })
}

export const fetchRecipe = (id) => async (dispatch) => {
  const response = await API.get(`/recipe/${id}`)

  dispatch({ type: FETCH_RECIPE, payload: response.data })
}

export const deleteRecipe = (id) => async (dispatch) => {
  await API.delete(`/recipe/${id}`)

  dispatch({ type: DELETE_RECIPE, payload: id })
  history.push('/recipes')
}

export const editRecipe = (id, formValues) => async (dispatch) => {
  const response = await API.patch(`/recipe/${id}`, formValues)

  dispatch({ type: EDIT_RECIPE, payload: response.data })
  history.push('/recipes')
}

// ----------------- Items ------------------ //
export const createItem = (formValues) => async (dispatch, getState) => {
  const userId = getState().auth.id
  const response = await API.post('/list-item', { ...formValues, userId })
  dispatch({ type: CREATE_ITEM, payload: response.data })
  history.push('/shopping-list')
}

export const fetchItem = (id) => async (dispatch) => {
  const response = await API.get(`/list-item/${id}`)

  dispatch({ type: FETCH_ITEM, payload: response.data })
}
export const fetchItems = () => async (dispatch, getState) => {
  const userId = getState().auth.id
  const response = await API.get(`/list-item/user/${userId}`)

  dispatch({ type: FETCH_ITEMS, payload: response.data })
}

export const deleteItem = (id) => async (dispatch) => {
  await API.delete(`/list-item/${id}`)

  dispatch({ type: DELETE_ITEM, payload: id })
  history.push('/shopping-list')
}

export const editItem = (id, formValues) => async (dispatch) => {
  const response = await API.patch(`/list-item/${id}`, formValues)

  dispatch({ type: EDIT_ITEM, payload: response.data })
  history.push('/shopping-list')
}

// ----------------- Cart Items ------------------ //
export const createCartItem = (formValues, { item, modification }) => async (
  dispatch,
  getState
) => {
  const userId = getState().auth.id
  const response = await API.post('/cart-item', {
    ...formValues,
    item,
    modification,
    userId,
  })
  dispatch({ type: CREATE_CART_ITEM, payload: response.data })
  history.push('/shopping-list')
}

export const fetchCartItem = (id) => async (dispatch) => {
  const response = await API.get(`/cart-item/${id}`)

  dispatch({ type: FETCH_CART_ITEM, payload: response.data })
}
export const fetchCartItems = () => async (dispatch, getState) => {
  const userId = getState().auth.id
  const response = await API.get(`/cart-item/user/${userId}`)

  dispatch({ type: FETCH_CART_ITEMS, payload: response.data })
}

export const deleteCartItem = (id) => async (dispatch) => {
  await API.delete(`/cart-item/${id}`)

  dispatch({ type: DELETE_CART_ITEM, payload: id })
  history.push('/shopping-list')
}

export const editCartItem = (id, formValues) => async (dispatch) => {
  const response = await API.patch(`/cart-item/${id}`, formValues)

  dispatch({ type: EDIT_CART_ITEM, payload: response.data })
  history.push('/shopping-list')
}

// ----------------- Setting ------------------ //
export const createSetting = (formValues) => async (dispatch, getState) => {
  const userId = getState().auth.id
  const response = await API.post('/setting', {
    ...formValues,
    userId,
  })
  dispatch({ type: CREATE_SETTING, payload: response.data })
  history.push('/')
}

export const fetchSetting = (id) => async (dispatch, getState) => {
  const userId = getState().auth.id
  const response = await API.get(`/setting/user/${userId}`)

  dispatch({ type: FETCH_SETTING, payload: response.data })
}

export const editSetting = (id, formValues) => async (dispatch) => {
  const response = await API.patch(`/setting/${id}`, formValues)

  dispatch({ type: EDIT_SETTING, payload: response.data })
  history.push('/')
}
