import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import GoogleAuth from '../googleAuth'

class Header extends React.Component {
  render() {
    return (
      <div className="ui secondary pointing menu">
        <Link to="/" className="item">
          RecipeBook
        </Link>
        <div className="right menu">
          {this.props.isSignedIn ? (
            <Fragment>
              <Link to="/recipes" className="item">
                All Recipes
              </Link>
              <Link to="/shopping-list" className="item">
                Shopping List
              </Link>
              <Link to="/settings" className="item">
                Settings
              </Link>
            </Fragment>
          ) : null}
          <GoogleAuth />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
  }
}

export default connect(mapStateToProps, {})(Header)
