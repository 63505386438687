import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import './App.scss'
import RecipeCreate from './recipes/RecipeCreate'
import RecipeEdit from './recipes/RecipeEdit'
import RecipeDelete from './recipes/RecipeDelete'
import RecipeShow from './recipes/RecipeShow'
import RecipeList from './recipes/RecipeList'
import Header from './header'
import history from '../history'
import ItemList from './items/ItemList'
import ItemCreate from './items/ItemCreate'
import ItemEdit from './items/ItemEdit'
import ItemDelete from './items/ItemDelete'
import ItemComplete from './items/ItemComplete'
import CartItemList from './cartItems/CartItemList'
import CartItemCreate from './cartItems/CartItemCreate'
import CartItemEdit from './cartItems/CartItemEdit'
import CartItemDelete from './cartItems/CartItemDelete'
import LandingPage from './landingPage'
import requireAuth from './requireAuth'
import Settings from './settings/Settings'

const App = () => {
  return (
    <div className="ui container">
      <Router history={history}>
        <div>
          <Header />
          <Switch>
            <Route path="/" exact component={LandingPage} />
            <Route path="/recipes" exact component={requireAuth(RecipeList)} />
            <Route
              path="/recipes/new"
              exact
              component={requireAuth(RecipeCreate)}
            />
            <Route
              path="/recipes/edit/:id"
              exact
              component={requireAuth(RecipeEdit)}
            />
            <Route
              path="/recipes/delete/:id"
              exact
              component={requireAuth(RecipeDelete)}
            />
            <Route
              path="/recipes/:id"
              exact
              component={requireAuth(RecipeShow)}
            />
            <Route
              path="/shopping-list"
              exact
              component={requireAuth(ItemList)}
            />
            <Route
              path="/shopping-list/item/new"
              exact
              component={requireAuth(ItemCreate)}
            />
            <Route
              path="/shopping-list/item/edit/:id"
              exact
              component={requireAuth(ItemEdit)}
            />
            <Route
              path="/shopping-list/item/delete/:id"
              exact
              component={requireAuth(ItemDelete)}
            />
            <Route
              path="/shopping-list/item/complete/:id"
              exact
              component={requireAuth(ItemComplete)}
            />
            <Route
              path="/shopping-list/cart-item/new"
              exact
              component={requireAuth(CartItemCreate)}
            />
            <Route
              path="/shopping-list/cart-item/edit/:id"
              exact
              component={requireAuth(CartItemEdit)}
            />
            <Route
              path="/shopping-list/cart-item/delete/:id"
              exact
              component={requireAuth(CartItemDelete)}
            />
            <Route path="/settings" exact component={requireAuth(Settings)} />
          </Switch>
          <Route
            path="/shopping-list"
            exact
            component={requireAuth(CartItemList)}
          />
        </div>
      </Router>
    </div>
  )
}

export default App
