import React from 'react'
import renderError from './formError'

const renderSelect = ({ input, label, meta, children }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`
  return (
    <div className={className}>
      <label>{label}</label>
      <div>
        <select {...input} placeholder={label}>
          {children}
        </select>
        {renderError(meta)}
      </div>
    </div>
  )
}

export default renderSelect
