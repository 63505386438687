import React from 'react'
import { Link } from 'react-router-dom'
import { Field, FieldArray, reduxForm } from 'redux-form'
import renderInput from '../form/formInput'

class SettingForm extends React.Component {
  renderUnits = ({ fields, meta }) => (
    <React.Fragment>
      <h4>Units</h4>
      <ul>
        {fields.map((unit, index) => (
          <li key={index}>
            <button
              className="ui icon red button"
              type="button"
              title="Remove Unit"
              onClick={() => fields.remove(index)}
            >
              <i className="trash icon"></i>
            </button>
            <Field
              name={`${unit}.unit`}
              type="text"
              component={renderInput}
              label={`Unit`}
            />
          </li>
        ))}
        <li>
          <button
            className="ui labeled icon button"
            type="button"
            onClick={() => fields.push()}
          >
            <i className="plus icon"></i>Add Unit
          </button>
        </li>
      </ul>
      {(meta.touched || meta.submitFailed) && meta.error && (
        <div className={`${meta.error ? 'error' : ''}`}>
          <div className="ui error message">
            <div className="header">{meta.error}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  )

  renderStores = ({ fields, meta }) => (
    <React.Fragment>
      <h4>Stores</h4>
      <ul>
        {fields.map((store, index) => (
          <li key={index}>
            <button
              className="ui icon red button"
              type="button"
              title="Remove Store"
              onClick={() => fields.remove(index)}
            >
              <i className="trash icon"></i>
            </button>
            <Field
              name={`${store}.store`}
              type="text"
              component={renderInput}
              label={`Store`}
            />
          </li>
        ))}
        <li>
          <button
            className="ui labeled icon button"
            type="button"
            onClick={() => fields.push()}
          >
            <i className="plus icon"></i>Add Store
          </button>
        </li>
      </ul>
      {(meta.touched || meta.submitFailed) && meta.error && (
        <div className={`${meta.error ? 'error' : ''}`}>
          <div className="ui error message">
            <div className="header">{meta.error}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  )

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues)
  }

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field name="currency" component={renderInput} label="Currency" />
        <FieldArray name="units" component={this.renderUnits} />
        <FieldArray name="stores" component={this.renderStores} />
        <button className="ui button primary">Submit</button>
        <Link to="/" className="ui button">
          Cancel
        </Link>
      </form>
    )
  }
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.currency) {
    errors.title = 'You must enter a currency'
  }
  if (!formValues.units || !formValues.units.length) {
    errors.units = { _error: 'You must have at least one unit' }
  } else {
    const unitArrayErrors = []
    formValues.units.forEach((unit, index) => {
      const unitErrors = {}
      if (!unit || !unit.unit) {
        unitErrors.unit = 'you must enter a unit'
        unitArrayErrors[index] = unitErrors
      }
    })
    if (unitArrayErrors.length) {
      errors.units = unitArrayErrors
    }
  }
  if (!formValues.stores || !formValues.stores.length) {
    errors.stores = { _error: 'You must have at least one store' }
  } else {
    const storeArrayErrors = []
    formValues.stores.forEach((store, index) => {
      const storeErrors = {}
      if (!store || !store.store) {
        storeErrors.store = 'you must enter a store'
        storeArrayErrors[index] = storeErrors
      }
    })
    if (storeArrayErrors.length) {
      errors.stores = storeArrayErrors
    }
  }

  return errors
}

export default reduxForm({
  form: 'settingForm',
  validate,
})(SettingForm)
