import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class LandingPage extends React.Component {
  renderLanding() {
    if (this.props.isSignedIn) {
      return (
        <ul>
          <li>
            Add recipes to your account.{' '}
            <Link to="/recipes/new">New Recipe</Link>
          </li>
          <li>
            Browse your recipes. <Link to="/recipes">Recipes</Link>
          </li>
          <li>
            Add items to your shopping list.{' '}
            <Link to="/shopping-list/item/new">New Shopping List Item</Link>
          </li>
          <li>
            Purchase items on you List.{' '}
            <Link to="/shopping-list">Shopping List</Link>
          </li>
        </ul>
      )
    } else {
      return <p>Please sign in to get started.</p>
    }
  }

  render() {
    return (
      <div>
        <h1>Welcome to Recipe Book to Shopping List</h1>
        <p>
          This project was created to make it easier to create a recipe list and
          shop based off of that list.
        </p>
        {this.renderLanding()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
  }
}

export default connect(mapStateToProps, {})(LandingPage)
