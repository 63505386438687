import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import history from '../history'

export default (ChildComponent) => {
  const ComposedComponent = (props) => {
    const { isSignedIn } = props

    useEffect(() => {
      if (!isSignedIn && isSignedIn != null) {
        history.push('/')
      }
    }, [isSignedIn])

    return <div>{isSignedIn ? <ChildComponent {...props} /> : null}</div>
  }

  const mapStateToProps = (state) => {
    return {
      isSignedIn: state.auth.isSignedIn,
    }
  }

  return connect(mapStateToProps)(ComposedComponent)
}
