import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { createItem, fetchSetting } from '../../actions'
import ShoppingForm from './ItemForm'

class ItemCreate extends React.Component {
  componentDidMount() {
    this.props.fetchSetting()
  }

  onSubmit = (formValues) => {
    this.props.createItem(formValues)
  }

  render() {
    return (
      <div>
        <h3>Create A Shopping List Item</h3>
        <ShoppingForm
          initialValues={_.pick(
            this.props.item,
            'item',
            'amount',
            'unit',
            'modification'
          )}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

export default connect(mapStateToProps, { createItem, fetchSetting })(
  ItemCreate
)
