import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import renderInput from '../form/formInput'
import renderSelect from '../form/formSelect'

class ItemCompletedForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues)
  }

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field
          name={`item`}
          type="text"
          component={renderInput}
          label={`Item`}
        />
        <Field
          name={`amount`}
          type="number"
          component={renderInput}
          label={`Amount purchased`}
        />
        <Field
          name={`cost`}
          type="number"
          component={renderInput}
          label={`Cost per Unit`}
        />
        <Field name={`unit`} component={renderSelect} label={`Unit`}>
          <option />
          <option value="teaspoon">teaspoon(s)</option>
          <option value="tablespoon">tablespoon(s)</option>
          <option value="cup">cup(s)</option>
          <option value="gallon">gallon(s)</option>
          <option value="fluid-ounces">fluid ounce(s)</option>
          <option value="pint">pint(s)</option>
          <option value="quart">quart(s)</option>
          <option value="ounce">ounce(s)</option>
          <option value="pound">pound(s)</option>
          <option value="slice">slice(s)</option>
          <option value="piece">piece(s)</option>
          <option value="stalk">stalk(s)</option>
          <option value="sheet">sheet(s)</option>
          <option value="handful">handful(s)</option>
          <option value="floret">floret(s)</option>
          <option value="bulb">bulb(s)</option>
          <option value="sprig">sprig(s)</option>
          <option value="fillet">fillet(s)</option>
          <option value="bundle">bundle(s)</option>
          <option value="bag">bag(s)</option>
          <option value="bunch">bunch(es)</option>
          <option value="wedge">wedge(s)</option>
          <option value="clove">clove(s)</option>
          <option value="package">package(s)</option>
          <option value="pinch">pinch(s)</option>
          <option value="head">head(s)</option>
        </Field>

        <Field name={`store`} component={renderSelect} label={`Store`}>
          <option />
          <option value="smiths">Smiths</option>
          <option value="sprouts">Sprouts</option>
          <option value="walmart">Wal-Mart</option>
        </Field>

        <button className="ui button positive">Complete</button>
        <Link to="/shopping-list" className="ui button">
          Cancel{' '}
        </Link>
      </form>
    )
  }
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.store) {
    errors.store = 'You must enter an store'
  }
  if (!formValues.amount) {
    errors.amount = 'You must enter a amount'
  }
  if (!formValues.unit) {
    errors.unit = 'You must enter an unit'
  }
  return errors
}

export default reduxForm({
  form: 'itemCompletedForm',
  validate,
})(ItemCompletedForm)
