import React from 'react'

export default function renderError({ error, touched }) {
  if (touched && error) {
    return (
      <div className="ui error message">
        <div className="header">{error}</div>
      </div>
    )
  }
}
