import { FETCH_SETTING, EDIT_SETTING } from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_SETTING:
      return {
        ...state,
        0: action.payload,
      }
    case EDIT_SETTING:
      return { ...state, 0: action.payload }
    default:
      return state
  }
}
