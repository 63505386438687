import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { fetchCartItem, editCartItem } from '../../actions'
import CartItemForm from './CartItemForm'

class CartItemEdit extends React.Component {
  componentDidMount() {
    this.props.fetchCartItem(this.props.match.params.id)
  }

  onSubmit = (formValues) => {
    this.props.editCartItem(this.props.match.params.id, formValues)
  }

  render() {
    if (!this.props.item) {
      return (
        <div>
          <h2>Here</h2>
        </div>
      )
    }
    return (
      <div>
        <h3>Edit A Shopping Cart Item</h3>
        <CartItemForm
          initialValues={_.pick(
            this.props.item,
            'item',
            'amount',
            'cost',
            'unit',
            'modification',
            'store'
          )}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return { item: state.cartItems[ownProps.match.params.id] }
}

export default connect(mapStateToProps, { fetchCartItem, editCartItem })(
  CartItemEdit
)
