export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'

export const CREATE_RECIPE = 'CREATE_RECIPE'
export const FETCH_RECIPES = 'FETCH_RECIPES'
export const FETCH_RECIPE = 'FETCH_RECIPE'
export const DELETE_RECIPE = 'DELETE_RECIPE'
export const EDIT_RECIPE = 'EDIT_RECIPE'

export const CREATE_ITEM = 'CREATE_ITEM'
export const FETCH_ITEMS = 'FETCH_ITEMS'
export const FETCH_ITEM = 'FETCH_ITEM'
export const DELETE_ITEM = 'DELETE_ITEM'
export const EDIT_ITEM = 'EDIT_ITEM'

export const CREATE_CART_ITEM = 'CREATE_CART_ITEM'
export const FETCH_CART_ITEMS = 'FETCH_CART_ITEMS'
export const FETCH_CART_ITEM = 'FETCH_CART_ITEM'
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM'
export const EDIT_CART_ITEM = 'EDIT_CART_ITEM'

export const CREATE_SETTING = 'CREATE_SETTING'
export const FETCH_SETTING = 'FETCH_SETTING'
export const EDIT_SETTING = 'EDIT_SETTING'
