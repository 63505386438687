import _ from 'lodash'
import React from 'react'
import Modal from '../modal'
import history from '../../history'
import { connect } from 'react-redux'
import { fetchItem, createCartItem, deleteItem } from '../../actions'
import ItemCompletedForm from './ItemCompletedForm'

class ItemComplete extends React.Component {
  componentDidMount() {
    this.props.fetchItem(this.props.match.params.id)
  }

  onSubmit = (formValues) => {
    this.props.deleteItem(this.props.match.params.id)
    this.props.createCartItem(formValues, this.props.item)
  }

  renderContent() {
    if (!this.props.item) {
      return 'Are you sure you want to complete this shopping list item?'
    }
    return (
      <div>
        <p>
          Are you sure you want to complete the shopping list item:
          {this.props.item.item}
        </p>
        <h3>Completion Detials</h3>
        <ItemCompletedForm
          initialValues={_.pick(
            this.props.item,
            'item',
            'cost',
            'amount',
            'unit',
            'store'
          )}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }

  render() {
    return (
      <Modal
        title="Complete Shopping List Item"
        content={this.renderContent()}
        onDismiss={() => history.push('/shopping-list')}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return { item: state.items[ownProps.match.params.id] }
}

export default connect(mapStateToProps, {
  fetchItem,
  createCartItem,
  deleteItem,
})(ItemComplete)
