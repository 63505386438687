import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchRecipes } from '../../actions'
import { isMobile } from 'react-device-detect'

class RecipeList extends React.Component {
  componentDidMount() {
    this.props.fetchRecipes()
  }

  renderAdmin(recipe) {
    if (recipe.userId === this.props.currentUserId) {
      return (
        <div
          className={`right floated content ${
            isMobile ? 'ui icon buttons' : ''
          }`}
        >
          <Link to={`/recipes/edit/${recipe.id}`} className="ui button primary">
            {isMobile ? <i className="icon pencil alternate" /> : 'Edit'}
          </Link>
          <Link
            to={`/recipes/delete/${recipe.id}`}
            className="ui button negative"
          >
            {isMobile ? (
              <i className="icon trash alternate outline" />
            ) : (
              'Delete'
            )}
          </Link>
        </div>
      )
    }
  }

  renderList() {
    return this.props.recipes.map((recipe) => {
      if (this.props.currentUserId !== recipe.userId) return null
      return (
        <div className="item" key={recipe.id}>
          {this.renderAdmin(recipe)}
          <div className="content">
            <Link to={`/recipes/${recipe.id}`} className="header">
              {recipe.title}
            </Link>
          </div>
          <div className="description">{recipe.description}</div>
        </div>
      )
    })
  }

  renderCreate() {
    if (this.props.isSignedIn) {
      return (
        <div
          className={`${isMobile ? 'ui icon buttons' : ''}`}
          style={{ textAlign: 'right' }}
        >
          <Link to="/recipes/new" className="ui button primary">
            {isMobile ? <i className="icon plus" /> : 'Create Recipe'}
          </Link>
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        <h2 className="ui floated left header">Recipes</h2>
        {this.renderCreate()}
        <div className="ui celled list">{this.renderList()}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    recipes: Object.values(state.recipes),
    currentUserId: state.auth.id,
    isSignedIn: state.auth.isSignedIn,
  }
}

export default connect(mapStateToProps, { fetchRecipes })(RecipeList)
