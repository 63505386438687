import React from 'react'
import { connect } from 'react-redux'
import { fetchSetting } from '../../actions'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import renderInput from '../form/formInput'
import renderSelect from '../form/formSelect'

class ItemForm extends React.Component {
  componentDidMount() {
    this.props.fetchSetting()
  }

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues)
  }

  getUnits = (units) => {
    return units.map((x, index) => (
      <option key={index} value={x['unit']}>
        {x['unit']}
      </option>
    ))
  }

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field
          name={`item`}
          type="text"
          component={renderInput}
          label={`Item`}
        />
        <Field
          name={`amount`}
          type="number"
          component={renderInput}
          label={`Amount`}
        />

        <Field name={`unit`} component={renderSelect} label={`Unit`}>
          <option />
          {this.props.settings
            ? this.getUnits(this.props.settings.units)
            : null}
        </Field>
        <Field
          name={`modification`}
          type="text"
          component={renderInput}
          label={`Modification`}
        />
        <button className="ui button primary">Submit</button>
        <Link to="/shopping-list" className="ui button">
          Cancel
        </Link>
      </form>
    )
  }
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.item) {
    errors.item = 'You must enter an item'
  }
  if (!formValues.amount) {
    errors.amount = 'You must enter a amount'
  }
  if (!formValues.unit) {
    errors.unit = 'You must enter an unit'
  }
  return errors
}

const mapStateToProps = (state) => {
  return { settings: state.settings[0] }
}

export default reduxForm({
  form: 'itemForm',
  validate,
})(connect(mapStateToProps, { fetchSetting })(ItemForm))
