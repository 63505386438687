import React, { Fragment } from 'react'
import renderError from './formError'

const renderInput = ({ input, label, type = 'text', meta, unit = null }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`
  return (
    <div className={className}>
      <label>{label}</label>
      {unit ? (
        <div className="ui right input labeled">
          <input {...input} type={type} />
          <div className="ui basic label">{unit}</div>
          {renderError(meta)}
        </div>
      ) : (
        <Fragment>
          <input {...input} type={type} />
          {renderError(meta)}
        </Fragment>
      )}
    </div>
  )
}

export default renderInput
