import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchRecipe } from '../../actions'

class RecipeShow extends React.Component {
  componentDidMount() {
    this.props.fetchRecipe(this.props.match.params.id)
  }

  renderAdmin(recipe) {
    if (recipe.userId === this.props.currentUserId) {
      return (
        <div className="right floated content">
          <Link to={`/recipes/edit/${recipe.id}`} className="ui button primary">
            Edit
          </Link>
          <Link
            to={`/recipes/delete/${recipe.id}`}
            className="ui button negative"
          >
            Delete
          </Link>
        </div>
      )
    }
  }

  render() {
    if (!this.props.recipe) {
      return <div>Loading</div>
    }

    const {
      title,
      description,
      prep,
      cook,
      makes,
      servings,
      servingunit,
      ingredients,
      directions,
    } = this.props.recipe

    return (
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
        <ul>
          <li>Total Time: {prep + cook}</li>
          <li>Prep Time: {prep}</li>
          <li>Cook Time: {cook}</li>
          <li>Serves: {makes}</li>
          <li>
            Serving Size: {servings} {servingunit ? `${servingunit}(s)` : ''}
          </li>
        </ul>
        <h5>Ingredients</h5>
        <ul>
          {ingredients.map(
            ({ amount, unit, ingredient, modification }, index) => (
              <li
                key={index}
              >{`${amount} ${unit} ${modification} ${ingredient}`}</li>
            )
          )}
        </ul>
        <h5>Directions</h5>
        <ol>
          {directions.map(({ step }, index) => (
            <li key={index}>{`${step}`}</li>
          ))}
        </ol>
        {this.renderAdmin(this.props.recipe)}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    recipe: state.recipes[ownProps.match.params.id],
    currentUserId: state.auth.id,
    isSignedIn: state.auth.isSignedIn,
  }
}

export default connect(mapStateToProps, { fetchRecipe })(RecipeShow)
