import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import { fetchCartItems, fetchSetting } from '../../actions'

class CartItemList extends React.Component {
  componentDidMount() {
    this.props.fetchCartItems()
    this.props.fetchSetting()
  }

  renderAdmin(item) {
    if (item.userId === this.props.currentUserId) {
      return (
        <div
          className={`right floated content ${
            isMobile ? 'ui icon buttons' : ''
          }`}
        >
          <Link
            to={`/shopping-list/cart-item/edit/${item.id}`}
            className="ui button primary"
          >
            {isMobile ? <i className="icon pencil alternate" /> : 'Edit'}
          </Link>
          <Link
            to={`/shopping-list/cart-item/delete/${item.id}`}
            className="ui button negative"
          >
            {isMobile ? (
              <i className="icon trash alternate outline" />
            ) : (
              'Delete'
            )}
          </Link>
        </div>
      )
    }
  }

  renderFooter(currency) {
    if (this.props.isSignedIn) {
      const user = this.props.currentUserId
      var result = this.props.cartItems.reduce(function (tot, arr) {
        if (user !== arr.userId) return tot
        return tot + arr.amount * arr.cost
      }, 0)
      return ` ${currency + result.toFixed(2)}`
    }
  }

  renderItems(currency) {
    return this.props.cartItems.map((item, index) => {
      if (this.props.currentUserId !== item.userId) return null
      return (
        <div className="item" key={index}>
          <div className="content">
            <div className="header">{item.item}</div>
            <div className="meta">
              <div className="floated right">{this.renderAdmin(item)}</div>
              <span className="amount">
                {item.amount} {item.unit ? `${item.unit}(s)` : ''} at
              </span>
              <span className="amount-per-unit">
                {this.props.settings.currency + (item.cost * 1).toFixed(2)} per{' '}
                {item.unit}
              </span>
            </div>
            <div className="description">
              <span className="total">
                Total: {currency + (item.amount * item.cost).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      )
    })
  }

  renderCreate() {
    if (this.props.isSignedIn) {
      return (
        <div
          className={`${isMobile ? 'ui icon buttons' : ''}`}
          style={{ textAlign: 'right' }}
        >
          <Link to="/shopping-list/cart-item/new" className="ui button primary">
            {isMobile ? (
              <i className="icon plus" />
            ) : (
              'Create Shopping Cart Item'
            )}
          </Link>
        </div>
      )
    }
  }

  render() {
    return (
      <Fragment>
        <div className="ui horizontal divider">And</div>
        <div>
          <h2 className="ui floated left header">Shopping Cart</h2>
          <div className="floated right">{this.renderCreate()}</div>
          <div className="ui divided items">
            {this.props.settings
              ? this.renderItems(this.props.settings.currency)
              : null}
            <div className="item">
              <div className="content">
                <div className="header floated right">
                  Total:{' '}
                  {this.props.settings
                    ? this.renderFooter(this.props.settings.currency)
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: Object.values(state.cartItems),
    currentUserId: state.auth.id,
    isSignedIn: state.auth.isSignedIn,
    settings: state.settings[0],
  }
}

export default connect(mapStateToProps, { fetchCartItems, fetchSetting })(
  CartItemList
)
