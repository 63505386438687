import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import authReducer from './authReducer'
import itemReducer from './itemReducer'
import recipeReducer from './recipeReducer'
import cartItemReducer from './cartItemReducer'
import settingReducer from './settingReducer'

export default combineReducers({
  auth: authReducer,
  recipes: recipeReducer,
  items: itemReducer,
  cartItems: cartItemReducer,
  settings: settingReducer,
  form: formReducer,
})
